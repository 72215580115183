@import "../../../assets/scss/variable";

.afa-dashboard-header {
  background-color: #fff;
  z-index: 10;
  position: fixed;
  left: 0;
  top: 0;
  height: 83px;
  width: 100%;
  -webkit-box-shadow: 0 3px 50px -15px #707070ab;
  -moz-box-shadow: 0 3px 50px -15px #707070ab;
  box-shadow: 0 3px 50px -15px #707070ab;

  .afa-user-info {
    background-color: $main_color;
    color: #fff;
    border-radius: 23px;
    height: 46px;

    i {
      font-size: 2.5rem;
    }
  }

  .afa-bell-img {
    width: 46px;
    height: 46px;

    .icon-bell {
      font-size: 34px;
    }
  }

  .afa-header-messages {
    position: absolute;
    left: 1.7em;
    bottom: 1.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    min-width: 27px;
    min-height: 18px;
    background-color: $main_color;
    color: #fff;
    font-size: 18px;
  }
}

.afa-sidebar-menu {
  z-index: 9;
  background-color: #fff;
  position: fixed;
  height: 100%;
  overflow-y: auto;
  width: 225px;
  top: 83px;
  padding-bottom: 5rem !important;
  -webkit-box-shadow: -2px 0px 18px -10px rgba(112, 112, 112, 1);
  -moz-box-shadow: -2px 0px 18px -10px rgba(112, 112, 112, 1);
  box-shadow: -2px 0px 18px -10px rgba(112, 112, 112, 1);

  .afa-exit-btn {
    border: none;
    background-color: transparent;
  }

  .afa-dashboard-tab-icons {
    span {
      font-size: 22px;
      color: #C5C5C5;
    }
  }

  .afa-dashboard-span {
    color: #C5C5C5;
    font-size: 14px;
  }

  .react-tabs__tab {
    list-style: none !important;
    margin: 0.5rem 0;
    font-size: 14px;
  }

  .react-tabs__tab.react-tabs__tab--selected {
    background-color: #fff;
    border-right: 4px solid $main_color;
    color: $main_color;

    .afa-dashboard-span, .afa-dashboard-tab-icons span {
      font-size: 20px;
      color: $main_color;

      &.afa-dashboard-span {
        font-size: 16px !important;
      }
    }
  }
}

.date-input-with-dialog-calendar, .date-input-with-dialog-empty {
  margin: 0 0.5rem !important;
}

.afa-sidebar-logo-img {
  height: 83px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
}

.afa-sidebar-logo-menu {
  height: 83px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 10;
  top: 1.85rem;
  right: 2rem;
  cursor: pointer;
}

#dropdown-user-info {
  background: transparent;
  border: none !important;
  color: #191A1B;
  box-shadow: none !important;

  &.dropdown-toggle::after {
    margin-right: 0.5rem !important;
  }
}

.dropdown-item {
  text-align: right !important;
  background-color: #fff !important;
}

.f-28 {
  font-size: 28px !important;
}

.afa-content-dashboard, .afa-content-dashboard-mobile {
  padding: 4rem 5rem;
  width: calc(100% - 200px);
  height: calc(100% - 83px);
  position: absolute;
  left: 0;
  bottom: 0;
}

.bg-gray {
  background-color: #F8F8F8;
  padding: 1rem 0;
  margin-bottom: 2rem;
}

.dv-dropdown-items-text {
  font-size: 14px !important;
  text-align: right !important;
  padding: 5px 10px;
}

.dv-dropdown-menu-content {
  min-width: 250px !important;
  padding: 0 !important;
}

.dv-logout-bg {
  background-color: #e7e7e7;
  padding: 10px;
}

.dv-no-item-found {
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
}


.afa-bg-edc-doc {
  background: #F6F6F6;
  border-radius: 8px;
  padding: 1.5rem;
  margin: 1rem;
}

select {
  background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right #ddd;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  background-position-x: 20px;
}

.afa-part-title {
  color: $main_color;
  line-height: 24px;
  font-size: 16px;
  font-weight: bold;
}

.active_menu {
  background-color: #fff;
  border-right: 4px solid $main_color;
  color: $main_color;
}

.active_menu .afa-dashboard-span, .active_menu .afa-dashboard-tab-icons span {
  //font-size: 20px;
  color: $main_color;
  span.path1:before {
    //font-size: 20px;
    color: $main_color !important;
  }
}

/**/
.active_menu .afa-dashboard-span {
  font-size: 14px !important;
}

a.link-color {
  color: $main_color;
  font-size: 16px;
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;

  span {
    font-size: 20px;
    padding-right: 0.5rem;
  }

  &:hover {
    color: $main_color;
  }
}

#afa-article-content {
  width: calc(100% - 225px);
  margin-right: auto;
  margin-top: 130px;
}

#afa_user_table {
  width: 100%;

  thead {
    position: sticky;
    top: -1px;
    z-index: 9;
    background: #fff;

    td {
      font-size: 14px;
      color: #C5C5C5;
    }
  }

  tbody {
    tr {
      &.afa-inactive-user {
        .afa-tbody-text {
          color: #e2e2e2;
        }
      }

      td {
        padding-bottom: 1rem;
        min-width: 100px;

        span.afa-empty-start {
          color: #C5C5C5;
          font-size: 18px;
        }

        span.afa-gold-start {
          color: #E5BF00;
          font-size: 18px;
        }

        &.afa-user-width {
          width: 100px;
          padding-left: 1.25rem;
        }

        img.afa-user-avatar {
          border-radius: 50%;
          object-fit: cover;
          width: 40px;
          height: 40px;
          border: 1px solid #ECECEC;
        }

        .afa-tbody-text {
          color: #808080;
          font-size: 14px;
        }

        .afa-user-status-active-table-btn {
          font-size: 12px;
          background: #18ADD5;
          border: none !important;
          border-radius: 10px;
          color: #fff;
          transition: all 0.3s linear;
          width: 89px;
          height: 33px;
          margin-left: 0.75rem;

          &:hover {
            background: #1998ba;
          }
        }

        .afa-show-staff {
          font-size: 12px;
          background: #18ADD5;
          border: none !important;
          border-radius: 10px;
          color: #fff;
          transition: all 0.3s linear;
          width: 102px;
          height: 33px;
          margin-left: 0.75rem;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            background: #0e9fc6;
            color: #fff;
          }
        }

        &.afa-table-btns-width {
          width: 180px;
        }
      }
    }
  }
}

.afa-permission-checkbox {
  background: #fff;
  border-radius: 10px;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center;
  width: 100%;
  height: 50px;
  margin-bottom: 1rem;
  -webkit-box-shadow: 0 4px 30px -13px #707070ab;
  -moz-box-shadow: 0 4px 30px -13px #707070ab;
  box-shadow: 0 4px 30px -13px #707070ab;

  input:checked ~ .checkmark2 {
    background-color: #59DE97 !important;
  }

  .checkmark2 {
    width: 25px;
    height: 25px;
    border-radius: 50% !important;
    left: 1rem !important;
    right: auto !important;
    top: 0.8rem !important;

    &:after {
      top: 1px !important;
      left: 5.5px !important;
      font-size: 15px !important;
    }
  }

  span {
    color: #82919A;
    font-size: 14px;
  }
}

.afa-notifications-tab {
  .afa-notification-item:nth-child(even) {
    .accordion__button, .accordion__panel {
      background: #fff;
    }
  }

  .accordion__panel {
    background: #f4f4f4;
    margin-top: -0.5rem;
    border-radius: 0 0 10px 10px;
  }

  .accordion__button {
    display: flex;
    justify-content: space-between;
    border-radius: 10px !important;

    &:before {
      margin-top: 8px !important;
    }

    div.d-flex {
      @media (max-width: 768px) {
        flex-flow: wrap;
        text-align: right;
        //flex-direction: column;
      }
    }
  }
}

.afa-upload-file-parent {
  max-width: 300px;
  margin-right: auto;
  margin-left: 0;
  display: block;
}

.ck.ck-editor__editable_inline {
  border: 1px solid #0000002e !important;
  min-height: 400px;
}

.search-wrapper {
  text-align: right !important;
  display: flex;
  flex-flow: wrap;

  input {
    width: auto !important;
    margin-right: 1rem !important;
  }
}

.react-tagsinput {
  span {
    display: flex;
    flex-flow: wrap;

    .react-tagsinput-tag {
      direction: ltr;
      font-family: iranYekan !important;
      background-color: #ECECEC !important;
      border-radius: 24px !important;
      border: none !important;
      padding: 0.3rem 0.3rem 0.3rem 0.86rem;
      min-width: 80px !important;
      text-align: right;
      display: flex !important;
      width: fit-content;
      justify-content: flex-end;
      align-items: center;

      .react-tagsinput-remove {
        margin-left: 0.5rem;

        &:before {
          width: 29px;
          height: 29px;
          background-color: #C5C5C5 !important;
          font-size: 24px !important;
          border-radius: 24px;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .react-tagsinput-input {
      display: inline-block;
      width: 100%;
      margin-right: 0.5rem;
      border: none !important;
      border-right: 1.5px solid #16A596 !important;

    }
  }
}

.afa-product-tabs {
  border-bottom: 1.5px solid #C5C5C5;
  width: 320px;
  padding: 0 !important;

  .react-tabs__tab {
    width: 50%;
    text-align: center;
    color: #C5C5C5;
    font-size: 16px;

    &.react-tabs__tab--selected {
      color: $main_color;
      border: none;
      border-bottom: 1.5px solid $main_color;
    }
  }
}

.afa-product-option-title {
  color: #605757;
  font-size: 14px;
}

.afa-product-option-value-bottom {
  color: #379521;
  font-size: 18px;
}

.afa-product-option-value-top {
  color: $main_color;
  font-size: 18px;
}

.ml-2-rem {
  margin-left: 2rem;
}

.ml-25-rem {
  margin-left: 2.5rem;
}

.m-auto {
  margin: auto;
}

.afa-product-edit-icon {
  font-size: 22px;
  margin-left: 0.75rem;
}

.afa-chart-select-value {
  width: 80px;
  color: $main_red;
  font-size: 13px;
  font-weight: normal;
  border: none;
  background: transparent;
  appearance: auto !important;
  background-position-x: 5px;

  &:focus {
    outline: 1px solid $main_color;
  }
}

.dv-price-table-parent{
  max-height: 400px;
  overflow-y: auto;

  .afa-product-table {
    border: 1px solid #E3D6CE;
    width: 100%;
    direction: ltr;

    thead {
      position: sticky;
      top: -5px;
      tr {
        background-color: #FBF4EF;
        border-radius: 4px 4px 0 0;
        border: 1px solid #E3D6CE;

        th {
          padding: 0.75rem;
          color: #936B52;
          font-size: 13px;
          text-align: center;

          button {
            color: #936B52;
            font-size: 13px;
            font-weight: bold;
            background-color: transparent;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: auto;

            span {
              padding: 0 0.15rem;

              &.afa-rotate {
                transform: rotate(180Deg);
              }
            }
          }
        }
      }
    }

    tbody {
      tr {
        border: 1px solid #E3D6CE;
        background-color: #FFF;

        td {
          padding: 1rem;
          color: #936B52;
          font-size: 13px;
          text-align: center;
        }
      }
    }
  }
}



.css-6j8wv5-Input {
  display: flex;
  align-items: center;
}

.css-1s2u09g-control, .css-1pahdxg-control {
  border: none !important;
  border-radius: 10px !important;

  .css-319lph-ValueContainer, .css-1hb7zxy-IndicatorsContainer {
    background: #ECECEC;
    padding: 0.4rem;
  }

  .css-1hb7zxy-IndicatorsContainer {
    border-radius: 10px 0 0 10px !important;
  }

  .css-319lph-ValueContainer {
    border-radius: 0 10px 10px 0 !important;
  }
}

.css-qc6sy-singleValue {
  span.icon-telegram, span.icon-whatsapp, span.icon-linkedin {
    font-size: 22px !important;
    padding-left: 1rem;
  }

  span.icon-telegram span, span.icon-whatsapp span {
    font-size: 22px !important;
  }
}

.afa-add-link {
  color: #fff;
  font-size: 28px;
  background: $main_color;
  padding: 0.72rem 1rem;
  border-radius: 10px;
  margin-top: 0.7rem;
  margin-right: 0.75rem;
  cursor: pointer;
}

.afa-remove-link {
  font-size: 24px;
  margin-top: 1.5rem;
  margin-right: 0;
  margin-left: 0.5rem;
  padding: 0.6rem 0.8rem;
  color: $main_red;
  cursor: pointer;
}

.afa-notification-count {
  background: $main_color;
  min-width: 26px;
  min-height: 20px;
  border-radius: 25px;
  color: #fff !important;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -10px;
  right: -10px;
}

.afa-logs-divs {
  border-radius: 10px;
  padding: 1rem;

  &:nth-child(odd) {
    background-color: #F6F6F6;
  }

  &:nth-child(even) {
    background: #FFF;
  }
}

.leaflet-container {
  z-index: 0 !important;
}

.leaflet-marker-icon {
  margin-top: -45px !important;
}

.dv_add_filter_range {
  background: $main_color;
  border-radius: 10px;
  color: #fff;
  padding: 5px;
  border: none;
  transition: all 0.3s linear;

  &:hover {
    background: red;
  }
}

.dv-required-star {
  color: $main_color !important;
  font-size: 1em !important;
  padding-right: 0.35em;
}

.show_units_btn {
  background: #D01936;
  border-radius: 10px;
  border: none;
  padding: 1rem;
  height: 50px !important;
  display: flex;
  align-items: center;
  color: #fff;
  transition: all 0.3s linear;
  justify-content: center;
  font-size: 16px;
  margin-left: 1rem;
}

.show_units_btn:hover {
  background: $main_color;
}

.afa-shba-number-input{
  background-color: #ECECEC !important;
}

@media (min-width: 992px) {
  .afa-user-status-modal {
    .modal-dialog {
      min-width: 650px;
    }
  }
  .w-md-50 {
    width: 50% !important;
  }
  .pl-md-2 {
    padding-left: 0.7em;
  }
}

@media (max-width: 992px) {
  #afa-article-content {
    width: calc(100%);
    margin-right: 0;
    margin-top: 90px;
  }
  .add-new-btn {
    width: 250px !important;
  }
  #afa_user_table {
    tr {
      td {
        min-width: 180px;
        width: fit-content;
      }
    }
  }
}

@media (max-width: 576px) {
  .afa-content-dashboard, .afa-content-dashboard-mobile {
    padding: 3rem 0.5rem;
  }
  .rzjdY span.icon-cloud-computing {
    right: 43% !important;
    top: -80% !important;
  }
  .rzjdY span.upload-text {
    right: 26% !important;
    top: 60% !important;
    font-size: 12px !important;
  }
  .afa-custom-checkbox {
    padding-left: 0 !important;
  }
  input::placeholder {
    font-size: 13px !important;
  }
  .cbuncq {
    max-width: 100% !important;
  }
  .show_units_btn {
    width: 100%;
    margin-bottom: 1em;
    margin-left: 0;
  }
}
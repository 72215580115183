@import "variable";

html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  height: 100%;
  font-size: 16px;
  line-height: 27px;
  font-family: iranYekan, sans-serif !important;
}

button, input, a, select, option, textarea {
  font-family: iranYekan, sans-serif !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

ul {
  padding-right: 0 !important;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.rtl {
  direction: rtl;
}

.ltr {
  direction: ltr;
}

a {
  transition: all 0.3s linear !important;
  text-decoration: none !important;
}

input:focus, textarea:focus {
  border: 1px solid $main-red;
}

.f-14 {
  font-size: 14px;
}

.f-24 {
  font-size: 24px !important;
}

.f-22 {
  font-size: 22px !important;
}

.f-20 {
  font-size: 20px !important;
}

.Toastify__toast-body {
  margin: auto 0;
  flex: 1 1 auto;
  padding: 6px;
  text-align: right !important;
  font-family: 'iranYekan' !important;
}

.tab-content {
  width: 100%;
}

.ltr {
  direction: ltr;
}

.light-scroll-theme {
  /* width */
  &::-webkit-scrollbar, *::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track, *::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb, *::-webkit-scrollbar-thumb {
    background: $main-red;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover, *::-webkit-scrollbar-thumb:hover {
    background: #18284c;
  }
}

.dark-scroll-theme {
  overflow-y: auto;
  /* width */
  &::-webkit-scrollbar, *::-webkit-scrollbar {
    width: 8px !important;
  }

  /* Track */
  &::-webkit-scrollbar-track, *::-webkit-scrollbar-track {
    background: #f1f1f1 !important;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb, *::-webkit-scrollbar-thumb {
    background: $main-yellow !important;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover, *::-webkit-scrollbar-thumb:hover {
    background: #E6C97EFF !important;
  }
}

.nav-tabs {
  border: none;
  background-color: #5A5A5A;
  border-radius: 50px !important;
  padding: 1px 0;
}

#login-tabs-tab-legal.nav-link, #login-tabs-tab-real.nav-link {
  background-color: #5A5A5A;
  padding: 0.65rem 4rem;
  border: none;
  color: #fff;
  border-radius: 50px !important;

  &.active {
    background-color: $main-red;
  }
}

.timer {
  position: absolute;
  width: 300px;
  margin: auto;
  text-align: center;
  background: #191A1B;
  padding: 2rem;
  font-size: 16px;
  color: #3eba09;

  .dv-again-link {
    cursor: pointer;
  }
}

@keyframes k-loading-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.k-i-loading.k-example-loading {
  font-size: 64px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgb(144, 152, 165);
}

.k-i-loading.k-example-loading::before,
.k-i-loading.k-example-loading::after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-block;
  content: "";
  box-sizing: inherit;
  border-radius: 50%;
  border-width: .05em;
  border-style: solid;
  border-color: currentColor;
  border-top-color: transparent;
  border-bottom-color: transparent;
  background-color: transparent;
}

.k-icon.k-i-loading.k-example-loading::before,
.k-icon.k-i-loading::after {
  content: "";
}

.k-i-loading.k-example-loading::before {
  margin-top: -0.5em;
  margin-left: -0.5em;
  width: 1em;
  height: 1em;
  animation: k-loading-animation .7s linear infinite;
}

.k-i-loading.k-example-loading::after {
  margin-top: -0.25em;
  margin-left: -0.25em;
  width: .5em;
  height: .5em;
  animation: k-loading-animation reverse 1.4s linear infinite;
}

.example-wrapper {
  min-height: 280px;
  align-content: flex-start;
}

.example-wrapper p, .example-col p {
  margin: 20px 0 10px;
}

.example-wrapper p:first-child, .example-col p:first-child {
  margin-top: 0;
}

.example-col {
  display: inline-block;
  vertical-align: top;
  padding-right: 20px;
  padding-bottom: 20px;
}

.example-config {
  margin: 0 0 20px;
  padding: 20px;
  background-color: rgba(0, 0, 0, .03);
  border: 1px solid rgba(0, 0, 0, .08);
}

.event-log {
  margin: 0;
  padding: 0;
  max-height: 100px;
  overflow-y: auto;
  list-style-type: none;
  border: 1px solid rgba(0, 0, 0, .08);
  background-color: white;
}

.event-log li {
  margin: 0;
  padding: .3em;
  line-height: 1.2em;
  border-bottom: 1px solid rgba(0, 0, 0, .08);
}

.event-log li:last-child {
  margin-bottom: -1px;
}

.date-input-with-dialog-calendar, .date-input-with-dialog-calendar:hover {
  border-color: transparent !important;
  color: $main-red;
  background-color: transparent;
}

.JDatePicker .JC-Section-Year, .JDatePicker .day-items:hover, .JDatePicker .day-items.selected, .JDatePicker button:hover, .JDatePicker .day-items.today:hover {
  background-color: $main-red;
}

.JDatePicker button {
  border-color: $main-red;
  transition: all 0.25s linear;
}

.date-input-with-dialog-empty {
  border-color: $main-red;
  color: $main-red;
}

.afa-error-text {
  color: $main-red;
  font-size: 14px;
}

.afa-error-input {
  font-size: 14px;
  border: 2px solid $main-red !important;
  border-radius: 10px;
}

/* Hide the browser's default radio button */
.afa-custom-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.afa-custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark, .checkmark2 {
  position: absolute;
  top: 0;
  right: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 50%;
}

.checkmark2 {
  border-radius: 5px !important;
}

/* On mouse-over, add a grey background color */
.afa-custom-checkbox:hover input ~ .checkmark, .afa-custom-checkbox:hover input ~ .checkmark2 {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.afa-custom-checkbox input:checked ~ .checkmark, .afa-custom-checkbox input:checked ~ .checkmark2 {
  background-color: #D01936;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after, .checkmark2:after {
  font-family: 'icomoon';
  content: "\e910" !important;
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.afa-custom-checkbox input:checked ~ .checkmark:after, .afa-custom-checkbox input:checked ~ .checkmark2:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.afa-custom-checkbox .checkmark:after, .afa-custom-checkbox .checkmark2:after {
  top: -2px;
  left: 4.5px;
  color: #fff;
  font-size: 12px;
  //border-radius: 50%;
  //background: white;
}

.afa-custom-checkbox {
  padding-right: 30px;
  font-size: 14px;
  line-height: 24px;
}

.icon-close-solid {
  background-color: #fff;
  border-radius: 50%;
  font-size: 24px;
  color: $main-red;
  position: absolute;
  top: -5px;
  right: -2px;
  cursor: pointer;
}

//.afa-remove-position{}
span.pr-3 {
  padding-right: 1rem;
}

.accordion {
  margin-top: 40px;

  .card {
    border: none;
    margin-bottom: 20px;

    .card-header {
      cursor: pointer;
      border-right: 6px solid $main-red;
      background: #F8F8F8;
      border-bottom: none !important;

      .dv-icon-delete {
        color: $main-red;
        font-size: 20px;
        cursor: pointer;
      }

      .dv-collapse-arrow {
        position: absolute;
        left: 20px;
        top: 20px;
        font-size: 22px;
      }
    }

    .card-body {
      background: #F8F8F8;
      margin: 0;
    }
  }
}

.pl-2 {
  padding-left: 0.75rem;
}

.pl-4 {
  padding-left: 1.5rem;
}

.afa-img-size {
  width: 85px;
  height: 85px;
  margin: 0.75rem;

  img {
    width: 100%;
    height: 100%;
  }
}

.afa-add-more-btn {
  border-radius: 8px !important;
}

.swal2-confirm.btn-success {
  margin-left: 0.5rem;
}

.styles_react-code-input__CRulA {
  input {
    color: #fff !important;
  }
}

.text-red {
  color: $main-red !important;
}

.jneUDS, .dNbWJJ, .ckAHgq {
  width: 80% !important;
  height: 80% !important;
}

.cEUfZw, .cOtVER, .boronx, .gbmEkQ, .cUUbsf, .bQGFax, .btWKdk, .fsERlW, .itvoqo, .BXRXG, .dSWqyw, .bRdJRl {
  width: 6px !important;
  height: 6px !important;
  background-color: #fff !important;
}

.sc-kgoBCf {
  margin: 0 !important;
  inset: auto !important;
  left: 5% !important;
  top: 23% !important;
}

.dqbMYY {
  width: 25px !important;
  height: 25px !important;
}

.dv-skill-loader {
  .sc-kgoBCf.dqbMYY {
    width: 30px !important;
    height: 30px !important;
    top: 40px !important;

    div {
      div {
        border-radius: 50% !important;
        width: 6px;
        height: 6px;
      }
    }
  }
}

/* width */
::-webkit-scrollbar {
  width: 12px;
}

.afa-sidebar-menu::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $main-btn-color;
  border-radius: 10px;
}

.btn-check:active + .btn-primary, .btn-check:checked + .btn-primary, .btn-primary.active, .btn-primary:active, .show > .btn-primary.dropdown-toggle {
  background: transparent;
  color: #000 !important;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.13) !important;
}

.cursor-pointer {
  cursor: pointer;
}

.afa-top-inputs {
  background: #ECECEC;
  padding: 1rem;
  border-radius: 8px;
  color: #0a0c0d;
  width: 100%;
  border: none;
  margin-left: 0.5rem;
  @media (max-width: 576px) {
    width: 100%;
  }

  &::placeholder {
    color: #C2C2C2;
  }

  &:focus {
    border: none;
    box-shadow: none;
    outline: none;
  }
}

.afa-textarea {
  background: #ECECEC;
  padding: 1rem;
  border-radius: 8px;
  color: #0a0c0d;
  border: none;
  width: 100%;

  &::placeholder {
    color: #C2C2C2;
  }

  &:focus {
    border: none;
    box-shadow: none;
    outline: none;
  }
}

.afa-btn-save-modal {
  border: none;
  border-radius: 8px;
  color: #fff;
  height: 50px;
  width: 70%;
  background: $main-red;
  transition: all 0.3s linear;
  margin-left: 1rem;

  &:hover {
    background: red;
  }
}

.afa-btn-cancel-modal {
  background: #BEBEBE;
  border: none;
  border-radius: 8px;
  color: #fff;
  height: 50px;
  width: 30%;
  transition: all 0.3s linear;

  &:hover {
    background: #a5a0a0;
  }
}

.afa-information-collapse {
  .accordion__button {
    background: #F6F6F6;
    direction: ltr;
    border-radius: 8px;
    padding: 1.5rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 576px) {
      flex-direction: column;
      align-items: flex-end;
      text-align: right;
      div {
        margin-bottom: 0.75rem;
      }
    }

    span.afa-title {
      color: #2F4858;
      padding-left: 0.25rem;
    }

    span.afa-value {
      color: #928f8f;
    }
  }

  .accordion__panel {
    background: #F6F6F6;
    display: flex;
    flex-flow: wrap;
    align-items: center;

    div {
      //display: flex;
      //align-items: center;
      font-size: 14px;
      padding-left: 2rem;
      margin-bottom: 2rem;

      span.afa-title {
        color: #2F4858;
        padding-left: 0.25rem;
      }

      span.afa-value {
        color: #928f8f;
      }

      span.afa-img-value {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 135px;
        height: 78px;
        background: #ECECEC;
        border-radius: 8px;
        border: 1px dashed $main-red;
        padding: 0.75rem;
        margin: 0.5rem;

        span.afa-img-title {
          color: $main-red;
          margin-bottom: 0.35rem;
          font-size: 10px;
          line-height: 15px;
        }

        img.afa-img-size-information {
          width: 37px;
          height: 33px;
          object-fit: contain;
          transform: scale(1);
          transition: all 0.3s linear;

          &:hover {
            transform: scale(5);
          }
        }

        img.afa-img-size-information2 {
          width: 68px;
          height: 45px;
          object-fit: contain;
          transform: scale(1);
          transition: all 0.3s linear;

          &:hover {
            transform: scale(5);
          }
        }
      }
    }
  }
}

span.afa-img-value2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 135px;
  height: 78px;
  background: #ECECEC;
  border-radius: 8px;
  border: 1px dashed $main-red;
  padding: 0.75rem;
  margin: 0.5rem;

  span.afa-img-title {
    color: $main-red;
    margin-bottom: 0.35rem;
    font-size: 10px;
    line-height: 15px;
  }

  img.afa-img-size-information {
    width: 37px;
    height: 33px;
    object-fit: contain;
    transform: scale(1);
    transition: all 0.3s linear;

    &:hover {
      transform: scale(5);
    }
  }

  img.afa-img-size-information2 {
    width: 68px;
    height: 45px;
    object-fit: contain;
    transform: scale(1);
    transition: all 0.3s linear;

    &:hover {
      transform: scale(5);
    }
  }
}

.afa-excel-export {
  border: none;
  border-radius: 10px;
  color: #fff;
  width: 200px;
  height: 50px;
  font-size: 16px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s linear;
  margin-left: 0.5rem;
  margin-bottom: 0 !important;
  @media (max-width: 576px) {
    width: 100%;
  }

  span.afa-btn-icon {
    font-size: 28px;
    padding-left: 0.5rem;
  }
}

.afa-excel-export2 {
  border: none;
  border-radius: 10px;
  color: #fff;
  width: 200px;
  height: 65px;
  font-size: 16px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s linear;
  margin-left: 0.5rem;
  @media (max-width: 576px) {
    width: 100%;
  }

  span.afa-btn-icon {
    font-size: 28px;
    padding-left: 0.5rem;
  }
}

.afa-lazyload {
  height: 600px;
  overflow-y: auto;
}

.afa-info-circle {
  color: red;
  font-size: 24px;
}

.dv-filter-drop-down-items .optionListContainer {
  position: unset !important;
}

.icon_cancel {
  margin-right: 5px;
  margin-left: 0 !important;
  width: 16px;
  height: 16px;
}

.searchWrapper {
  width: fit-content !important;
}

.optionContainer {
  width: fit-content !important;

  li {
    text-align: right !important;
  }
}

.dv-delete-price-table-item {
  font-size: 1em;
  color: $main-red;
  cursor: pointer;
}

.dv-save-lab-btn {
  border: none !important;
  background: #16A596;
  color: #fff;
  border-radius: 7px;
  font-size: 13px;
  padding: 0.7em 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s linear;
}

.dv-exam-titles {
  font-size: 0.8em;
}

.dv-exam-button {
  border: none !important;
  background: #16A596;
  color: #fff;
  border-radius: 7px;
  font-size: 13px;
  padding: 0.2em 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s linear;
}

.dv-units-filter-drop-down {
  border: none !important;

  .accordion__button {
    width: 280px;
    background: #fff;
    border: 1px solid #eee;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    direction: ltr;
    padding: 12px 18px;
    margin-right: 0.75em;

    &:hover, &:active {
      background: #fff;
      box-shadow: 0 3px 30px rgba(0, 0, 0, 0.13);
    }

    @media (max-width: 768px) {
      width: 100% !important;
    }
  }

  .accordion__panel {
    width: 280px;
    overflow-y: auto;
    background: #fff;
    border: 1px solid #eee;
    border-radius: 10px;
    margin-top: 1.5rem !important;
    position: absolute;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.13);
    z-index: 2;
    @media (max-width: 768px) {
      width: 100% !important;
      position: unset;
    }
  }

  .dv-filter-icon {
    color: $main-red;
    font-size: 24px;
    padding-left: 1rem;
  }

  @media (max-width: 768px) {
    width: 100% !important;
  }
}

.add-new-btn {
  background: $main-red;
  border-radius: 10px;
  border: none;
  padding: 1rem;
  height: 50px !important;
  display: flex;
  align-items: center;
  color: #fff !important;
  transition: all 0.3s linear;
  justify-content: center;
  font-size: 16px;
  margin-right: 1rem;
  width: 250px !important;


  @media (max-width: 576px) {
    width: 100% !important;
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .icon-add_paper {
    font-size: 24px;
    padding-left: 0.5rem;
  }

  &:hover {
    background: #E73444;
  }
}

.dv-back-to-parent-btn {
  background: $main-red;
  border-radius: 10px;
  border: none;
  padding: 0.5em 2.5em;
  display: flex;
  align-items: center;
  color: #fff !important;
  transition: all 0.3s linear;
  justify-content: center;
  font-size: 16px;
  margin-right: 1rem;


  @media (max-width: 576px) {
    width: 100% !important;
    margin-right: 0;
    margin-bottom: 1rem;
  }

  &:hover {
    background: red;
  }
}

.dv-w-auto {
  width: auto !important;
}

.afa-user-status-table-btn {
  font-size: 12px;
  background: #F2B067;
  border: none !important;
  border-radius: 10px;
  color: #fff;
  transition: all 0.3s linear;
  width: 89px;
  height: 33px;
  margin-left: 0.75rem;

  &:hover {
    background: #eea14c;
  }
}

.afa-table-edit-btn {
  border: 1px solid #E7E9F7;
  background: transparent;
  border-radius: 10px;
  width: 35px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #C5C5C5;
  font-size: 20px;
}

.afa-table-edit-btn-dark {
  color: #3C3C3C;
  border: none;
}

.dv-units-table-parent-bg {
  background: #ECECEC;
  padding: 2em;
  margin-bottom: 1em;
  border-radius: 8px;
  max-height: 300px;
  overflow-y: auto;
}

.dv-unit-item {
  transition: all 0.3s linear;
  margin-bottom: 1em;
  padding: 0.3em;

  &:hover {
    border-radius: 5px;
    background: #e5e5e5;
  }
}

.dv-guide-file-btn {
  margin-right: auto;
  margin-left: 3em;
  margin-bottom: 0 !important;
  width: auto !important;
  max-width: 260px;
  cursor: pointer;

  a {
    width: 100%;
  }

  &:hover a {
    background: red;
    color: #fff !important;
  }
}

.dv-contact-us-btn .show {
  box-shadow: none !important;
}

.dv-contact-us-btn .show > .btn-primary.dropdown-toggle {
  box-shadow: none !important;
  outline: none;
  width: 100% !important;

  &:after {
    margin-right: auto;
    margin-left: 1em;
    color: #c5c5c5;
  }

}

.dv-contact-us-btn button {
  width: 100% !important;
  padding: 0.5em 0 !important;
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.dv-contact-us-btn .dropdown-menu.show {
  width: 100%;
  position: relative !important;
  transform: translate(0, 0) !important;
}

.dv-contact-us-btn .dropdown-toggle:after {
  margin-right: auto !important;
  margin-left: 1em !important;
  color: #c5c5c5 !important;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dv-about-us-textarea .ck.ck-editor__editable_inline {
  min-height: 300px;
}

.dv-custom-select-interview{
  background: #fff;
  padding: 0.4em;
  color: #000;
  border: gainsboro;
  margin-right: 0.5em;
}

.dv-user-is-denied{
  padding-right: 1em;
}

.dv-user-is-denied input{
  accent-color : $main-red;
  padding-left: 0.25em;
}

.dv-user-is-denied span{
  padding-right: 0.25em;
}

span.afa-value {
  color: #928f8f;
}
span.afa-title {
  color: #2F4858;
  padding-left: 0.25rem;
}

.afa-text-red{
  color: $main-red;
}

.dv-ltr .ck.ck-content {
  text-align: left;
  direction: ltr;
}

.dv-rtl .ck.ck-content {
  text-align: right;
  direction: rtl;
}

.afa-save-super-admin-btn{
  background: $main-red;
  border-radius: 25px;
  color: #fff;
  padding: 8px 100px;
  border: none;
}

@media (max-width: 992px) {

  .dv-guide-file-btn {
    margin-top: 2em;
  }
  .afa-content-dashboard {
    width: 100%;
  }
  .afa-content-dashboard, .afa-content-dashboard-mobile {
    padding: 3rem 1rem;
  }
  .k-stepper {
    width: 45px !important;
    margin-right: auto;
    margin-left: 0;
  }
  .afa-size-parent {
    margin-top: 1rem;
    width: calc(100% - 45px) !important;
    position: relative;
  }
  .k-step-list-vertical ~ .k-progressbar {
    left: 22px !important;
  }
  .k-stepper .k-step-label {
    display: none !important;
  }
  .k-step-list-vertical, .k-stepper {
    width: 45px !important;
  }

}

@media (max-width: 576px) {

  .afa-upload-file-parent {
    max-width: 100% !important;
  }
  .dv-guide-file-btn {
    margin: 6em 1em 2em !important;
    width: 92% !important;
    max-width: 100%;
  }
  .afa-custom-checkbox {
    padding-left: 35px !important;
  }
  .styles_react-code-input__CRulA {
    display: flex;
    justify-content: center;

    input {
      width: 38px !important;
      height: 38px !important;
      margin: 0.2rem 0.2rem 2rem 0.2rem !important;
      padding: 0 !important;
    }
  }
  #login-tabs-tab-legal.nav-link, #login-tabs-tab-real.nav-link {
    padding: 0.65rem 3rem;
  }
}

.dv-next-btn{
  background-color: $next-btn-color !important;
  transition: all 0.3s linear;
  &:hover{
    filter: brightness(0.95);
  }
}

.dv-main-btn , .afa-excel-export2 , .afa-excel-export , .afa-btn-save-modal , .add-new-btn , .afa-save-super-admin-btn , .afa-add-link{
  background: $main-btn-color !important;
  transition: all 0.3s linear;
  &:hover{
    background: $main-btn-color-hover !important;
  }
}

.link-color{
  color: $next-btn-color !important;
}
.hidden{
    display: none;
}
.loader{
    z-index: 99999;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    background: rgba(0,0,0,0.3);
    .loadingio-spinner-ellipsis-vbzr0p1jua{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        justify-content: center;
        text-align: center;
        img{
            position: relative;
            top: 0;
            width: 60%;
        }
    }

}
@keyframes ldio-76msoii4h9c {
    0% { transform: translate(16.32px,85.68px) scale(1); }
    15% { transform: translate(16.32px,85.68px) scale(0); }
    30% { transform: translate(16.32px,85.68px) scale(0); }
    50% { transform: translate(16.32px,85.68px) scale(1); }
    75% { transform: translate(85.68px,85.68px) scale(1); }
    100% { transform: translate(155.04px,85.68px) scale(1); }
}
@keyframes ldio-76msoii4h9c-r {
    0% { transform: translate(155.04px,85.68px) scale(1); }
    100% { transform: translate(155.04px,85.68px) scale(0); }
}
@keyframes ldio-76msoii4h9c-c {
    0% { background: #233052 }
    15% { background: #233052 }
    30% { background: #233052 }
    50% { background: #233052 }
    75% { background: #233052 }
    100% { background: #233052 }
}
.ldio-76msoii4h9c div {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    transform: translate(85.68px,85.68px) scale(1);
    background: #233052;
    animation: ldio-76msoii4h9c 2.4390243902439024s infinite cubic-bezier(0,0.5,0.5,1);
}
.ldio-76msoii4h9c div:nth-child(1) {
    background: #233052;
    transform: translate(155.04px,85.68px) scale(1);
    animation: ldio-76msoii4h9c-r 0.6097560975609756s infinite cubic-bezier(0,0.5,0.5,1), ldio-76msoii4h9c-c 2.4390243902439024s infinite step-start;
}.ldio-76msoii4h9c div:nth-child(2) {
     animation-delay: -0.6097560975609756s;
     background: #233052;
 }.ldio-76msoii4h9c div:nth-child(3) {
      animation-delay: -1.2195121951219512s;
      background: #E73444;
  }.ldio-76msoii4h9c div:nth-child(4) {
       animation-delay: -1.8292682926829267s;
       background: #233052;
   }.ldio-76msoii4h9c div:nth-child(5) {
        animation-delay: -2.4390243902439024s;
        background: #E73444;
    }
.loadingio-spinner-ellipsis-vbzr0p1jua {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 250px;
    height: 190px;
    display: inline-block;
    overflow: hidden;
    background: #ffffff ;
    border-radius: 10px !important;
    box-shadow: RGBA(35, 48, 82,0.3) 0 3px 6px !important;
}
.ldio-76msoii4h9c {
    position: absolute;
    top: 4rem;
    width: 100%;
    height: 100%;
    transform: translateZ(0) translateX(33px) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
}
.ldio-76msoii4h9c div { box-sizing: content-box; }
.loader-text{
    font-size: 18px;
    position: absolute;
    top: 4rem;
    left: 3rem;
    color: #233052;
}
